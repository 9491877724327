import React from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import TrialDaysCounter from "../law-components/TrialDaysCounter";
import QuickActionsMenu from "../law-components/QuickActionsMenu";
import OverlayTutorial from "../containers/Frames/OverlayTutorial";
import useTutorialStateV2 from "../unstatedStores/TutorialStateV2";
import { VideoTutorialModal } from '../modals/VideoTutorialModal';
import { connect } from "react-redux";
import TrialDaysAlert from "../law-components/TrialDaysCounter/TrialDaysAlert";
import ReactGA from 'react-ga4';

const App = ({ match, authUser }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  //const tutorialState = TutorialStateContainer.useContainer();
  const tutorialState = useTutorialStateV2();
  return (
    <>
      <TrialDaysCounter />
      {/* <TrialDaysAlert /> */}
      <div className={avoidWrapperRoutes.includes(window.location.pathname) ? "" : "gx-main-content-wrapper"} style={avoidWrapperRoutes.includes(window.location.pathname) ? null : { marginTop: '2%', padding: `0px ${isTabletOrMobile ? '0' : '32px'} 0` }}>
        <VideoTutorialModal />
        <Switch>
          <Route path={`/home`} component={asyncComponent(() => import('../law-components/MainPage'))} />
          <Route path={`/main-page`} component={asyncComponent(() => import('../law-components/MainPage'))} />

          <Route exact path={`/metasign`} component={asyncComponent(() => import('./MetaSign'))} />
          <Route exact path={`/metasign/new-doc`} component={asyncComponent(() => import('./MetaSign/MetasignFormPage'))} />

          <Route exact path={`/settings`} component={asyncComponent(() => import('./ConfigPage'))} />
          <Route exact path={`/settings/:menu?/:studio?`} component={asyncComponent(() => import('./ConfigPage'))} />

          <Route exact path={`/settings/account`} component={asyncComponent(() => import('./ConfigPage/AccoutingConceptPage'))} />
          <Route exact path={`/studio-account`} component={asyncComponent(() => import('./StudioAccountsPage'))} />

          <Route exact path={`/settings/jstruct`} component={asyncComponent(() => import('./JudicialStructurePage'))} />

          <Route exact path={`/settings/indentification-types`} component={asyncComponent(() => import('./IdentificationTypePage'))} />

          <Route exact path={`/settings/movement-type`} component={asyncComponent(() => import('./ConfigPage/MovementTypePage'))} />
          <Route exact path={`/settings/expedient-status`} component={asyncComponent(() => import('./ConfigPage/ExpedientStatusPage'))} />

          <Route exact path={`/settings/clients`} component={asyncComponent(() => import('./ClientPage'))} />
          <Route exact path={`/settings/clients-types`} component={asyncComponent(() => import('./ConfigPage/ClientTypePage'))} />

          <Route exact path={`/expedient/`} component={asyncComponent(() => import('./ExpedientPage'))} />
          <Route exact path={`/expedient/filter`} component={asyncComponent(() => import('./ExpedientPage'))} />
          <Route exact path={`/expedient/details`} component={asyncComponent(() => import('./ExpedientPage/ExpedientDetailPage'))} />

          <Route exact path={`/fees`} component={asyncComponent(() => import('./FeesPage'))} />

          <Route exact path={`/expedient/add`} component={asyncComponent(() => import('./ExpedientPage/CreateExpedientPage'))} />
          <Route exact path={`/module/add`} component={asyncComponent(() => import('./AccessPage/CreateModulePage'))} />
          <Route exact path={`/access/add`} component={asyncComponent(() => import('./AccessPage/CreateAccessPage'))} />
          <Route exact path={`/user/add`} component={asyncComponent(() => import('./UsersPage/CreateUserPage'))} />

          <Route exact path={`/settings/indentification-types/add`} component={asyncComponent(() => import('./IdentificationTypePage/CreateIdentificationTypePage'))} />

          <Route exact path={`/contacts`} component={asyncComponent(() => import('./ClientPage'))} />
          <Route exact path={`/contact/add`} component={asyncComponent(() => import('./ClientPage/CreateContactPage'))} />
          <Route exact path={`/settings/contact-type`} component={asyncComponent(() => import('./ContactTypePage'))} />
          <Route exact path={`/contact-type/add`} component={asyncComponent(() => import('./ContactTypePage/CreateContactTypePage'))} />

          <Route exact path={`/settings/expedient-type`} component={asyncComponent(() => import('./ExpedientTypePage'))} />
          <Route exact path={`/settings/expedient-type/add`} component={asyncComponent(() => import('./ExpedientTypePage/CreateExpedientTypePage'))} />

          <Route exact path={`/invitation`} component={asyncComponent(() => import('./InvitationPage'))} />
          <Route exact path={`/invitation/send`} component={asyncComponent(() => import('./InvitationPage/SendInvitationPage'))} />

          <Route exact path={`/rtf`} component={asyncComponent(() => import('./RtfPage'))} />
          <Route exact path={`/appointment-settings`} component={asyncComponent(() => import('./ConfigPage/AppointmentSettingsPage'))} />
          <Route exact path={`/expense-list`} component={asyncComponent(() => import('./ExpenseList'))} />

          <Route exact path={`/schedule`} component={asyncComponent(() => import('./SchedulePage'))} />
          <Route exact path={`/statistics`} component={asyncComponent(() => import('./Statistics'))} />
          <Route exact path={`/listados`} component={asyncComponent(() => import('./List'))} />

          <Route exact path={`/mailbox`} component={asyncComponent(() => import('./Mailbox'))} />
          <Route exact path={`/llamadas`} component={asyncComponent(() => import('../law-components/CallLog/CallLogWithCalendar'))} />

          <Route exact path={`/tickets`} component={asyncComponent(() => import('./TicketsPage'))} />
          <Route exact path={`/ticket/details`} component={asyncComponent(() => import('./TicketsPage/components/TicketDetails'))} />
          <Route exact path={`/support`} component={asyncComponent(() => import('./SupportPage'))} />
          <Route exact path={`/faq`} component={asyncComponent(() => import('./Faqs/Faq'))} />
          {/* letters */}

          <Route exact path={`/document-letter`} component={asyncComponent(() => import('./LettersPage/CreateDocumentLetter'))} />
          <Route exact path={`/laboral-letter`} component={asyncComponent(() => import('./LettersPage/CreateLaboralLetter'))} />
          <Route exact path={`/letter-templates`} component={asyncComponent(() => import('./LettersPage/LettersTemplates'))} />
          <Route exact path={`/letter-templates/add`} component={asyncComponent(() => import('./LettersPage/CreateLetterTemplate'))} />
          <Route exact path={`/srt`} component={asyncComponent(() => import('../routes/SrtPage'))} />
          <Route exact path={`/srt/opcion-domicilio-letter`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateDomicilioOptionForm.jsx'))} />
          <Route exact path={`/srt/opcion-jurisdiccion-res-298-17`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateOpcionJurisdiccionForm.jsx'))} />
          <Route exact path={`/srt/opcion-de-competencia`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateOpcionCompetenciaForm.jsx'))} />
          <Route exact path={`/srt/patrocinio-letrado`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreatePatrocinioLetradoForm.jsx'))} />
          <Route exact path={`/srt/carta-poder`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateCartaPoderForm.jsx'))} />
          <Route exact path={`/srt/cymat`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateCymatForm.jsx'))} />
          <Route exact path={`/srt/formulario-apelacion`} component={asyncComponent(() => import('../law-components/Forms/Srt/CreateApelacionForm.jsx'))} />
          <Route exact path={`/expediente/importar-mev`} component={asyncComponent(() => import('./ImportExpedientsFromMev/ImportExpedientsFromMev.jsx'))} />

          {/* Rtf Editor */}
          <Route exact path={`/rtf-editor`} component={asyncComponent(() => import('./RtfPage/components/RtfEditorPage'))} />
          <Route exact path={`/expedient/rtf-editor`} component={asyncComponent(() => import('./RtfPage/components/RtfEditorPage'))} />
          <Route exact path={'/windowed/rtf-editor'} component={asyncComponent(() => import('./RtfPage/components/WindowedEditorPage'))} />

          {/* Calculadoras */}
          <Route exact path={`/calculadoras`} component={asyncComponent(() => import('./Calculadoras'))} />
          <Route exact path={`/settlements`} component={asyncComponent(() => import('./SettlementsPage'))} />
          <Route exact path={`/calculadoras/ripte`} component={asyncComponent(() => import('./Calculadoras/ripte'))} />
          <Route exact path={`/calculadoras/lrt`} component={asyncComponent(() => import('./Calculadoras/lrt'))} />
          <Route exact path={`/calculadoras/acta_cnat_2764`} component={asyncComponent(() => import('./Calculadoras/acta_cnat_2764'))} />
          <Route exact path={`/calculadoras/intereses_legales`} component={asyncComponent(() => import('./Calculadoras/intereses_legales'))} />
          <Route exact path={`/calculadoras/alimentos`} component={asyncComponent(() => import('./Calculadoras/alimentos'))} />
          <Route exact path={`/calculadoras/formula_irigoyen`} component={asyncComponent(() => import('./Calculadoras/formula_irigoyen'))} />
          <Route exact path={`/calculadoras/despidos`} component={asyncComponent(() => import('./Calculadoras/despidos'))} />
          <Route exact path={`/calculadoras/las_heras_requena_vuotto_mendez`} component={asyncComponent(() => import('./Calculadoras/las_heras_requena_vuotto_mendez'))} />
          <Route exact path={`/calculadoras/balthazard`} component={asyncComponent(() => import('./Calculadoras/balthazard'))} />
          <Route exact path={`/calculadoras/punto_incapacidad`} component={asyncComponent(() => import('./Calculadoras/punto_incapacidad'))} />
          <Route exact path={`/calculadoras/multitasa`} component={asyncComponent(() => import('./Calculadoras/multitasa'))} />
          <Route exact path={`/calculadoras/ripte_tasa_personalizada`} component={asyncComponent(() => import('./Calculadoras/ripte_tasa_personalizada'))} />
          <Route exact path={`/calculadoras/ipc_tasa_personalizada`} component={asyncComponent(() => import('./Calculadoras/ipc_tasa_personalizada'))} />
          <Route exact path={`/calculadoras/cer_tasa_personalizada`} component={asyncComponent(() => import('./Calculadoras/cer_tasa_personalizada'))} />
          <Route exact path={`/calculadoras/plenario_barrios`} component={asyncComponent(() => import('./Calculadoras/plenario_barrios'))} />
          <Route exact path={`/calculadoras/prorrateo`} component={asyncComponent(() => import('./Calculadoras/prorrateo'))} />


          <Route exact path={`/error-403`} component={asyncComponent(() => import('./Forbidden'))} />
        </Switch>
        {/* {(trialState ? (trialState.is_trial) : false) && <AltaButton />} */}
        {(tutorialState.step === 0
          && !isTabletOrMobile
          && !tutorialState.tutorialsCompleted.includes("DASHBOARD")
          && tutorialState.showTuto) && <OverlayTutorial />}
      </div>
    </>);
}

const avoidWrapperRoutes = ['/mailbox', '/settings', '/faq'];

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser }
};

export default connect(mapStateToProps, {})(App);
